.footer-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-image: url('../../Images/bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
}

.footer{
    width: 100%;
    min-height: 40vh;
    max-width: 100%;
    display: flex;
    flex-wrap: wrap;
    position: relative;
}

.footer-left{
    width: 50%;
    /* background-color: aquamarine; */
    padding: 1rem;
    display: flex;
    align-items: center;
}

.footer-logo{
    width: 50%;
}

.footer-right{
    width: 50%;
    /* background-color: rgb(17, 32, 27); */
    
}

.artboard {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 20px;
    position: relative;
}
.artboard:hover .button {
    transform: rotate(-75deg);
}
.button, .links {
    display: inline-block;
    width: 50%;
    height: 66.66667px;
    background: rgb(251,176,52);
    background: linear-gradient(90deg, rgba(251,176,52,1) 0%, rgba(255,208,35,1) 44%, rgba(255,221,0,1) 100%);
    box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
    color: rgb(255, 255, 255);
    display: flex;
    font-family: 'Rubik Moonrocks', cursive;
    text-transform: uppercase;
    justify-content: center;
    align-items: center;
    font-size: 24px;
}

.button {
    z-index: 11;
    transition: 950ms cubic-bezier(0.89, 0.03, 0.06, 1.5);
    transform-origin: top left;
}

.links {
    position: absolute;
    z-index: 9;
    color: black;
    background: #ffffff;
}

@keyframes beat {
    to {
        transform: scale(1.4);
   }
}

@media (max-width:1000px){
    .button, .links {
        width: 60%;
        font-size: 18px;
    }
}

@media (max-width:850px){
    .footer-left{
        width: 40%;
    }
    .footer-logo{
        width: 100%;
    }
    .footer-right{
        width: 60%;
    }
    .button, .links {
        width: 90%;
        font-size: 14px;
    }
}