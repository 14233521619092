body{
  background: rgb(251,176,52);
  background: linear-gradient(90deg, rgba(251,176,52,1) 0%, rgba(255,208,35,1) 44%, rgba(255,221,0,1) 100%);
  /* background-image: url('./Images/a.png');
  background-size: 50%;
  background-repeat: no-repeat;
  background-position: bottom; */
  font-family: 'Poppins', sans-serif;
  scroll-behavior: auto !important;
}

.App {
  width: 100%;
  max-width: 100%;
  overflow-x: hidden;
}

@media (max-width:1000px){
  body{
    cursor: unset;
  }
}
