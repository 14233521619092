.intro-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    background-image: url('../../Images/bg.png');
    background-color: white;
    background-repeat: no-repeat;
    background-size: cover;
    text-transform: uppercase;
    position: relative;
    overflow-x: hidden;
    font-family: 'Roboto', sans-serif;
    overflow-y:hidden ;
}

.intro{
    width: 100%;
    height: 100vh;
    max-width: 100%;
    /* display: flex;
    flex-wrap: wrap; */
    /* background-color: blueviolet; */
    overflow-x: hidden;
    position: relative;
}

#intro-wave{
    position: absolute;
    width: 100%;
    bottom: 0%;
}

#intro-boy{
    position: absolute;
    top: 30%;
    width: 20%;
    right: 10%;
}

.intro-logo-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    /* background-color: aqua; */
    margin-top: 15vh;
}

#intro-logo{
    width: 30%;
    animation: move 2s linear infinite;
}

.intro-btn-cont{
    width: 100%;
    padding: 1rem;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 32px;
}

.intro-btn{
   font-size: 24px;
   padding: 1rem;
   margin: 0px 20px;
   border-radius: 10px;
   outline: none;
   border: none;
   color: white;
   background: rgb(251,176,52);
background: linear-gradient(90deg, rgba(251,176,52,1) 5%, rgba(255,221,0,1) 100%);
   text-transform: uppercase;
   font-family: 'Rubik Moonrocks', cursive;
   cursor: pointer;
}

@keyframes move
{
    0% {
        transform: scale3d(1,1,1);
    }
    50% {
        transform: scale3d(1.1,1.1,1.1);
    }
    100% {
        transform: scale3d(1,1,1);
    }
}


@media(max-width:850px){
    .intro-cont{
        background-position: left bottom;
        /* background-image: url('../../Images/mobg.png'); */
    }

    .intro-logo-cont{
        margin-top: 20vh;
    }
    
    #intro-logo{
        width: 80%;
    }
}
