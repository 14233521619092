.about-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-image: url('../../Images/bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    
}

.about{
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* background-color: blueviolet; */
    
    position: relative;
}

.about-title-cont{
    padding: 1rem;
    margin-top: 10vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #ffffff;
    font-family: 'Rubik Moonrocks', cursive;
    text-transform: uppercase;
    width: 100%;
    transition: 1s ease-in-out;
    cursor: pointer;
}

.about-title-cont:hover{
    letter-spacing: 50px;
}

.about-text-cont{
    padding: 1rem;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    letter-spacing: 2px;
    text-align: center;
    font-size: 24px;
    color: #f7f7f7;
    font-family: 'Fredericka the Great', cursive;
    margin-top: 0px;
    
}

.about-img-cont{
    width: 100%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 26px;
}

.about-img{
    width: 10%;
    margin: 0px 20px;
    animation: rotate 2s linear infinite;
}

#about-rocket{
    width: 7%;
    transform: rotateZ(50deg);
}

@keyframes rotate
{
    0% {
        transform: rotateZ(0deg);
    }
    50% {
        transform: rotateZ(180deg);
    }
    100% {
        transform: rotateZ(360deg);
    }
}

@media(min-height:1020px){
    .about{
        min-height: 80vh;
    }

    .about-title-cont{
        font-size: 46px;
    }

    .about-text-cont{
        font-size: 30px;
    }
}



@media(max-width:850px){
    .about-title-cont{
        font-size: 36px;
        margin-top: 2vh;
    }

    .about-text-cont{
        font-size: 18px;
    }

    .about-img{
        width: 20%;
        margin: 0px 20px;
        animation: rotate 2s linear infinite;
    }
    
    #about-rocket{
        width: 20%;
        transform: rotateZ(50deg);
    }
}

@media(min-height:1100px){
    .about{
        min-height: 60vh;
    }
}