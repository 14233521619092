.team-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-image: url('../../Images/bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    overflow-y:hidden ;
}

.team-shape{
    position: absolute;
    width: 20%;
    animation: rotate 4s linear infinite;
}

#shape-1{
    right: 0%;
    top: 5%;
}


#shape-2{
    left: 25%;
    bottom: 5%;
}


#shape-3{
    left: -5%;
    top: 10%;
}


.team{
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* background-color: blueviolet; */
    overflow-x: hidden;
    position: relative;
}

.team-title-cont{
    padding: 1rem;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #ffffff;
    font-family: 'Rubik Moonrocks', cursive;
    text-transform: uppercase;
    width: 100%;
    transition: 1s ease-in-out;
    cursor: pointer;
}

.team-title-cont:hover{
    letter-spacing: 50px;
}

.team-cont-1{
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
}

.team-card-cont{
    width: 25%;
    padding: 1rem;
}

.team-card{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.team-img-cont{
    width: 250px;
    height: 250px;
    border-radius: 50%;
    border: 5px solid rgba(0,0,200,.1);
    box-shadow: 0 0 15px rgb(0 0 200 / 20%);
    overflow: hidden;
}

.team-img{
    width: 250px;
    height: 250px;
    object-fit: cover;
}

.team-name{
    width: 100%;
    font-size: 22px;
    text-align: center;
    color: white;
    margin-top: 10px;
    font-family: 'Rubik Moonrocks', cursive;
    letter-spacing: 10px;
    text-transform: uppercase;
}

.team-pos{
    width: 100%;
    text-align: center;
    margin-top: 10px;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

@media(max-width:850px){
    
    .team-shape{
        position: absolute;
        width: 40%;
        animation: rotate 4s linear infinite;
    }

    #shape-1{
        right:-5%;
        top: 15%;
    }
    
    
    #shape-2{
        left: 25%;
        bottom: -5%;
    }
    
    
    #shape-3{
        left: -5%;
        top: 10%;
    }

    .team-title-cont{
        font-size: 36px;
    }

    .team-card-cont{
        width: 50%;
    }

    .team-img-cont{
        width: 150px;
        height: 150px;
    }
    
    .team-img{
        width: 150px;
        height: 150px;
        object-fit: cover;
    }

    .team-name{
        width: 100%;
        font-size: 20px;
    }
    
    .team-pos{
        font-size: 16px;
    }

}

@media(min-height:1020px){

    .team-title-cont{
        font-size: 46px;
    }

    .team-cont-1{
        margin-top: 10vh;
    }

    .team-img-cont{
        width: 350px;
        height: 350px;
    }
    
    .team-img{
        width: 350px;
        height: 350px;
        object-fit: cover;
    }

    .team-name{
        width: 100%;
        font-size: 36px;
    }
    
    .team-pos{
        font-size: 26px;
    }
}

@media(min-height:1100px){
    .team-img-cont{
        width: 250px;
        height: 250px;
    }
    
    .team-img{
        width: 250px;
        height: 250px;
        object-fit: cover;
    }
}