*{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box;
}

.container-large{
  width: 100%;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  overflow-x: hidden;
  overflow-y: hidden;
}

.container-medium{
  width: 85%;
  max-width: 85%;
  position: relative;
}

.container-left{
  width: 50%;
}

.container-right{
  width: 50%;
}

.main-title{
  width: 100%;
  /* background-color: brown; */
  padding: 1rem;
  text-align: center;
  font-size: 36px;
  font-weight: bold;
  max-height: 12vh;
  position: relative;
}

@media(max-width:900px){

  .container-medium{
    width: 100%;
    max-width: 100%;
    position: relative;
  }

}
