.gameplay-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    /* background-image: url('../../Images/bg.png'); */
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    overflow-x: hidden;
    overflow-y:hidden ;
}

.gameplay{
    width: 100%;
    min-height: 100vh;
    max-width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    flex-wrap: wrap;
    /* background-color: blueviolet; */
    overflow-x: hidden;
    position: relative;
}

#boy{
    position: absolute;
    right: 0%;
    top: 10%;
}

.gameplay-title-cont{
    padding: 1rem;
    margin-top: 5vh;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 40px;
    color: #ffffff;
    font-family: 'Rubik Moonrocks', cursive;
    text-transform: uppercase;
    width: 100%;
    transition: 1s ease-in-out;
    cursor: pointer;
}

.gameplay-title-cont:hover{
    letter-spacing: 50px;
}

.gameplay-vid-cont{
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
}

.gameplay-vid{
    width: 70%;
}

@media(min-height:1020px) {
    .gameplay{
        min-height: 80vh;
    }

    .gameplay-title-cont{
        margin-top: 0px;
        font-size: 46px;
    }

    #boy{
        right: 0%;
        width: 25%;
    }
}

@media(max-width:850px){
    .gameplay{
        min-height: 70vh;
    }

    .gameplay-title-cont{
        font-size: 36px;
    }

    #boy{
        display: none;
    }

    .gameplay-vid{
        width: 90%;
    }
}

@media(min-height:1100px){
    .gameplay{
        min-height: 50vh;
    }
}